import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface UserState {
  user_permissions: string[];
  nav_permissions: any;
}

// Define the initial state using that type
const initialState: UserState = {
  user_permissions: [],
  nav_permissions: {},
};

export const permSlice = createSlice({
  name: "perm",
  initialState,
  reducers: {
    addPermission: (state: UserState, action: PayloadAction<string>) => {
      state.user_permissions = [...state.user_permissions, action.payload];
      return state;
    },
    userPermissions: (state: UserState, action: PayloadAction<string[]>) => {
      state.user_permissions = action.payload;
      return state;
    },
    addNavPermission: (state: UserState, action: PayloadAction<string[]>) => {
      state.nav_permissions = action.payload;
      return state;
    },
    removePermission: (state: UserState, action: PayloadAction<string>) => {
      state.user_permissions = state.user_permissions.filter((perm) => perm !== action.payload);
      return state;
    },
    clearPermission: (state: UserState) => {
      state.user_permissions = [];
      return state;
    },
  },
});

export const { addPermission, removePermission, clearPermission, addNavPermission, userPermissions } =
  permSlice.actions;

export default permSlice.reducer;
