import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { clearPermission } from "../../App/permSlice";
import { deleteUser } from "../../App/userSlice";
import Logo from "../../assets/images/KwikBet Logo FInal.png";
import MobileNav from "./MobileNav";

export default function SideBar() {
  let history = useHistory();
  const [toggleJackpot, setToggleJackpot] = useState<boolean>(false);
  const [toggleGames, setToggleGames] = useState<boolean>(false);
  const [togglePush, setTogglePush] = useState<boolean>(false);
  const [toggleAccMgt, setToggleAccMgt] = useState<boolean>(false);
  const [mobileNav, setMobileNav] = useState<boolean>(false);
  const [toggleIssues, setToggleIssues] = useState<boolean>(false);
  const { user_permissions, email } = useAppSelector((state) => state.user);
  const { nav_permissions } = useAppSelector((state) => state.perm);

  const dispatch = useAppDispatch();
  const handleJackpotToggle = (): void =>
    toggleJackpot ? setToggleJackpot(false) : setToggleJackpot(true);
  const handlePushToggle = (): void =>
    togglePush ? setTogglePush(false) : setTogglePush(true);
  const handleMobileNav = (): void =>
    mobileNav ? setMobileNav(false) : setMobileNav(true);
  const handleGamesToggle = (): void =>
    toggleGames ? setToggleGames(false) : setToggleGames(true);
  const handleAccToggle = (): void =>
    toggleAccMgt ? setToggleAccMgt(false) : setToggleAccMgt(true);
  const handleIssuesToggle = (): void =>
    toggleIssues ? setToggleIssues(false) : setToggleIssues(true);

  const handleAddJackpotClick = () => history.push("/add-jackpot");

  const handleAddGamesClick = () => {
    history.push("/add-games");
    setMobileNav(false);
  };

  const handleLogoutButton = () => {
    dispatch(deleteUser());
    dispatch(clearPermission());
    history.push("/");
  };

  return (
    <div className="flex lg:flex-col gap-6 lg:h-screen h-full border-r border-gray-200 bg-sideBar lg:items-start lg:justify-start  justify-between items-center relative">
      <div className="logo flex w-100 items-center lg:justify-center justify-start lg:-mt-4 z-20">
        <img
          src={Logo}
          alt="kwikbid_logo"
          className="object-contain lg:h-40 h-24"
        />
      </div>

      <div className="hidden lg:flex flex-col gap-6 ml-1 h-2/3 -mt-4 w-full">
        {nav_permissions.A && (
          <Link
            to="/deposits"
            className="group flex flex-row md:gap-6 sm:gap-1 cursor-pointer hover:border-l-4 hover:border-contrast rounded-sm"
          >
            <div className="md:ml-8 sm:ml-0">
              <SimCardDownloadOutlinedIcon style={{ fill: "white" }} />
            </div>
            <div className="text-white font-poppins font-bold ml-2">
              Transactions
            </div>
          </Link>
        )}

        {nav_permissions.C && (
          <Link
            to="/inbox"
            className="flex flex-row gap-6 ml-1 cursor-pointer hover:border-l-4 hover:border-contrast rounded-sm"
          >
            <div className="ml-8">
              <ForwardToInboxOutlinedIcon style={{ fill: "white" }} />
            </div>
            <div className="text-white font-poppins font-bold">Inbox</div>
          </Link>
        )}

        {/* <Link
          to="/outbox"
          className="flex flex-row gap-6 ml-1 cursor-pointer hover:border-l-4 hover:border-contrast rounded-sm"
        >
          <div className="ml-8">
            <OutboundOutlinedIcon style={{ fill: "white" }} />
          </div>
          <div className="text-white font-poppins font-bold">Outbox</div>
        </Link> */}

        {nav_permissions.E && (
          <div
            className="flex flex-col ml-1 cursor-pointer w-10/12"
            onClick={handleGamesToggle}
          >
            <div className="flex flex-row justify-between w-full bg">
              <div className="ml-8 mr-6 w-6 ">
                <SportsSoccerIcon style={{ fill: "white" }} />
              </div>
              <div className="text-white font-poppins font-bold flex-1">
                Games
              </div>
              <div>
                {toggleGames ? (
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} color="white" />
                )}
              </div>
            </div>
            {toggleGames && (
              <motion.div
                animate={{ y: 0 }}
                initial={{ y: -50 }}
                className="flex flex-col items-start w-full lg:ml-20 gap-2 ml-24"
                onClick={handleGamesToggle}
              >
                {user_permissions["E-001"] && (
                  <Link
                    to="/add-games"
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm w-full"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      Add Games
                    </div>
                  </Link>
                )}

                {user_permissions["E-002"] && (
                  <Link
                    to="/view-games"
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                  >
                    <div
                      className="text-white font-poppins font-normal ml-6"
                      onClick={handleAddGamesClick}
                    >
                      View Games
                    </div>
                  </Link>
                )}
              </motion.div>
            )}
          </div>
        )}

        {nav_permissions.F && (
          <div
            className="flex flex-col ml-1 cursor-pointer w-10/12 "
            onClick={handleJackpotToggle}
          >
            <div className="flex flex-row w-full">
              <div className="ml-8 mr-6 w-6">
                <MonetizationOnOutlinedIcon style={{ fill: "white" }} />
              </div>
              <div className="text-white font-poppins font-bold flex-1">
                Jackpot
              </div>
              <div>
                {toggleJackpot ? (
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} color="white" />
                )}
              </div>
            </div>
            {toggleJackpot && (
              <motion.div
                animate={{ y: 0 }}
                initial={{ y: -50 }}
                className="flex lg:ml-20 flex-col items-start gap-2"
              >
                {user_permissions["F-001"] && (
                  <Link
                    to="/add-jackpot"
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                  >
                    <div
                      className="text-white font-poppins font-normal ml-6"
                      onClick={handleAddJackpotClick}
                    >
                      Add Jackpot
                    </div>
                  </Link>
                )}

                {user_permissions["F-002"] && (
                  <Link
                    to="/view-jackpots"
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      View Jackpots
                    </div>
                  </Link>
                )}

                {user_permissions["F-003"] && (
                  <Link
                    to="/jackpot-slips"
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      Jackpot Slips
                    </div>
                  </Link>
                )}
              </motion.div>
            )}
          </div>
        )}
        {nav_permissions.G && (
          <div
            className="flex flex-col ml-1 cursor-pointer w-10/12 "
            onClick={handlePushToggle}
          >
            <div className="flex flex-row w-full">
              <div className="ml-8 mr-6 w-6">
                <NotificationsNoneIcon style={{ fill: "white" }} />
              </div>
              <div className="text-white font-poppins font-bold flex-1 select-none">
                Push Notifications
              </div>
              <div>
                {togglePush ? (
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} color="white" />
                )}
              </div>
            </div>
            {togglePush && (
              <motion.div
                animate={{ y: 0 }}
                initial={{ y: -50 }}
                className="flex lg:ml-20 flex-col items-start gap-2"
              >
                <Link
                  className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                  to="/web-notification"
                >
                  <div className="text-white font-poppins font-normal ml-6">
                    Web Notifications
                  </div>
                </Link>
              </motion.div>
            )}
          </div>
        )}
        {nav_permissions.H && (
          <div
            className="flex flex-col ml-1 cursor-pointer w-10/12 "
            onClick={handleAccToggle}
          >
            <div className="flex flex-row w-full">
              <div className="ml-8 mr-6 w-6">
                <PersonOutlineOutlinedIcon style={{ fill: "white" }} />
              </div>
              <div className="text-white font-poppins font-bold flex-1 select-none">
                Acc Management
              </div>
              <div>
                {togglePush ? (
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} color="white" />
                )}
              </div>
            </div>
            {toggleAccMgt && (
              <motion.div
                animate={{ y: 0 }}
                initial={{ y: -50 }}
                className="flex lg:ml-20 flex-col items-start gap-2"
              >
                {user_permissions["H-001"] && (
                  <Link
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                    to="/add-user"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      Add User
                    </div>
                  </Link>
                )}
                {user_permissions["H-001"] && (
                  <Link
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                    to="/view-user"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      View User
                    </div>
                  </Link>
                )}
              </motion.div>
            )}
          </div>
        )}
        {nav_permissions.I && (
          <div
            className="flex flex-col ml-1 cursor-pointer w-10/12 "
            onClick={handleIssuesToggle}
          >
            <div className="flex flex-row w-full">
              <div className="ml-8 mr-6 w-6">
                <ErrorOutlineIcon style={{ fill: "white" }} />
              </div>
              <div className="text-white font-poppins font-bold flex-1 select-none">
                Issues
              </div>
              <div>
                {togglePush ? (
                  <FontAwesomeIcon icon={faAngleRight} color="white" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} color="white" />
                )}
              </div>
            </div>
            {toggleIssues && (
              <motion.div
                animate={{ y: 0 }}
                initial={{ y: -50 }}
                className="flex lg:ml-20 flex-col items-start gap-2"
              >
                {user_permissions["I-001"] && (
                  <Link
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                    to="/issues/pending"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      Pending Issues
                    </div>
                  </Link>
                )}
                {user_permissions["I-001"] && (
                  <Link
                    className="text-white font-poppins font-normal mt-2 mb-1 hover:border-l-4 hover:border-contrast rounded-sm"
                    to="/issues/resolved"
                  >
                    <div className="text-white font-poppins font-normal ml-6">
                      Resolved Issues
                    </div>
                  </Link>
                )}
              </motion.div>
            )}
          </div>
        )}
      </div>

      <div className="hidden lg:flex gap-2 flex-row cursor-pointer rounded-sm ml-1 items-center">
        <div className="ml-8">
          <PersonOutlineOutlinedIcon
            style={{ fill: "white", height: "2rem", width: "2rem" }}
          />
        </div>
        <div className="text-white font-poppins font-bold">{email}</div>
        <div className="text-white font-poppins" onClick={handleLogoutButton}>
          Logout
        </div>
      </div>
      <div
        className="h-10 w-20  lg:hidden flex items-center justify-center cursor-pointer"
        onClick={handleMobileNav}
      >
        <MenuIcon style={{ fill: "white", height: "2rem", width: "3rem" }} />
      </div>
      {mobileNav && <MobileNav mobileNavBar={handleMobileNav} />}
    </div>
  );
}
