import { FC } from "react";
import { Link } from "react-router-dom";

const SideLinks: FC = () => {
  return (
    <div className="flex-1 hidden lg:flex lg:flex-col">
      <div className="hover:border-l-2 border-contrast rounded-sm mb-2">
        <Link to={`/users-registration`}>
          <h1 className="font-poppins font-light text-sm ml-4">
            Registered Users
          </h1>
        </Link>
      </div>
      <div className="hover:border-l-2 border-contrast rounded-sm">
        <Link to={`/inbox`}>
          <h1 className="font-poppins font-light text-sm ml-4 pb-2">Inbox</h1>
        </Link>
      </div>
      <div className="hover:border-l-2 border-contrast rounded-sm mb-2">
        <Link to={`/outbox`}>
          <h1 className="font-poppins font-light text-sm ml-4 pb-2">Outbox</h1>
        </Link>
      </div>
    </div>
  );
};

export default SideLinks;
