import React, { lazy, Suspense } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";

import Base from "./components/Layout/Base";
import Outbox from "./components/SMS/Outbox/Outbox";
import ProtectedRoutes from "./Protected.routes";
import SuperAdminRoutes from "./superAdmin.routes";

const listofPages: string[] = ["/"];

const Login = lazy(() => import("./components/Auth/Login"));

//All Deposits Routes

const Transaction = lazy(() => import("./components/Transactions/Transaction"));
const Broker = lazy(() => import("./components/Transactions/AllDeposits"));

//All SMS Routes
const Inbox = lazy(() => import("./components/SMS/Inbox/Inbox"));
const UsersRegistration = lazy(
  () => import("./components/SMS/Inbox/Registration")
);

//Jackpot Routes
const AddJackpot = lazy(() => import("./components/Jackpot/Jacpot"));
const ViewJackpots = lazy(
  () => import("./components/Jackpot/JackpotDataTable")
);
const JackpotDetails = lazy(
  () => import("./components/Jackpot/JackpotDetails")
);
const JackpotSlips = lazy(
  () => import("./components/Jackpot/JackpotPlacedBets")
);
const JackpotSlipsDetails = lazy(
  () => import("./components/Jackpot/JackpotSlipsDataTable")
);

//Game Routes
const AddGames = lazy(() => import("./components/Games/Games"));
const ViewGames = lazy(() => import("./components/Games/ViewGames"));

//Push Notifications
const WebNotifications = lazy(
  () => import("./components/PushNotifications/Web")
);

//Acc Management Routes
const AddUser = lazy(() => import("./components/AccMgt/AddUser"));
const ViewUsers = lazy(() => import("./components/AccMgt/ViewUsers"));
const EditUser = lazy(() => import("./components/AccMgt/EditUser"));

//Issues Routes
const Issues = lazy(() => import("./components/Issues/Issues"));
interface IProps extends RouteComponentProps<any> {
  basename: string;
}
const Routes: React.FC<IProps> = (props: IProps) => {
  if (listofPages.indexOf(props.location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <Suspense fallback={null}>
        <Switch>
          <Route path="/" component={Login} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Base>
        <Suspense fallback={null}>
          <Switch>
            <SuperAdminRoutes path="/deposits" component={Transaction} />
            <SuperAdminRoutes path="/broker-requests" component={Broker} />
            <SuperAdminRoutes path="/withdraw" component={Transaction} />
            <SuperAdminRoutes path="/add-jackpot" component={AddJackpot} />
            <SuperAdminRoutes path="/view-jackpots" component={ViewJackpots} />
            <SuperAdminRoutes
              path="/jackpot-details"
              component={JackpotDetails}
            />
            <SuperAdminRoutes path="/add-games" component={AddGames} />
            <SuperAdminRoutes path="/view-games" component={ViewGames} />
            <SuperAdminRoutes
              path="/users-registration"
              component={UsersRegistration}
            />
            <SuperAdminRoutes
              path="/web-notification"
              component={WebNotifications}
            />
            <ProtectedRoutes path="/jackpot-slips" component={JackpotSlips} />
            <ProtectedRoutes
              path="/jackpot/:id/slips"
              component={JackpotSlipsDetails}
            />
            <ProtectedRoutes path="/inbox" component={Inbox} />
            <ProtectedRoutes path="/outbox" component={Outbox} />
            <ProtectedRoutes path="/transactions" component={Transaction} />
            <ProtectedRoutes path="/add-user" component={AddUser} />
            <ProtectedRoutes path="/view-user" component={ViewUsers} />
            <ProtectedRoutes path="/edit-user/:id" component={EditUser} />
            <ProtectedRoutes path="/issues/pending" component={Issues} />
            <ProtectedRoutes path="/issues/resolved" component={Issues} />
          </Switch>
        </Suspense>
      </Base>
    );
  }
};

export default withRouter(Routes);
