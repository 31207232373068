import { motion } from "framer-motion";
import { FC } from "react";
import SideLinks from "../Inbox/SideLinks";
import OutboxDataTable from "../OutboxDataTable";

const Outbox: FC = (): JSX.Element => {
  return (
    <motion.div
      className="flex w-full h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
    >
      <div className="flex  flex-col w-5/6 h-full">
        <div className="ml-4 md:ml-12 font-poppins font-bold">
          <h1>Outbox</h1> <span className="font-light">500 Messages</span>
        </div>
        <div className="flex flex-1 justify-center mt-4 w-full">
          <div className="w-11/12">
            <OutboxDataTable
              datasourceURL={`${process.env.REACT_APP_SMS_API_URL}/outbox`}
              cacheName="outbox-sms"
            />
          </div>
        </div>
      </div>
      <div className="px-4 hidden flex-1 lg:flex lg:flex-col justify-start ">
        <h1 className="font-poppins font-bold text-lg mt-12 mb-10">
          Quick Links
        </h1>
        <SideLinks />
      </div>
    </motion.div>
  );
};

export default Outbox;
