import React, { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "./App/hooks";

interface Iprops {
  component: FC;
  [x: string]: any;
}

const ProtectedRoutes: FC<Iprops> = ({ component: Component, ...rest }): JSX.Element => {
  const user = useAppSelector((state) => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.loggedIn) {
          return <Component />;
        } else {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoutes;
