import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

// Define a type for the slice state
interface UserState {
  startDate: string | Date;
  endDate: string | Date;
  period?: string | Date;
}

// Define the initial state using that type
const initialState: UserState = {
  startDate: "",
  endDate: "",
  period: "",
};

export const dateSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectDate: (state, action: PayloadAction<UserState>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.period = action.payload.period;
    },
  },
});

export const { selectDate } = dateSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectUser = (state: RootState) => state.date;

export default dateSlice.reducer;
