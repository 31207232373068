import { FC } from "react";
import SideBar from "../SideBar/SideBar";

const Base: FC = ({ children }) => {
  return (
    <div className="flex flex-col lg:flex-row w-screen h-screen overflow-hidden">
      <div className="lg:w-1/5 w-full h-16 lg:h-full">
        <SideBar />
      </div>
      <section className="flex-grow bg-white">{children}</section>
    </div>
  );
};

export default Base;
