import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { deleteUser } from "../../App/userSlice";

interface Props {
  mobileNavBar: Function;
}

const MobileNav: FC<Props> = ({ mobileNavBar }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { nav_permissions } = useAppSelector((state) => state.perm);

  const [depositsMobile, setDepositsMobile] = useState<boolean>(false);
  const [withdrawalMobile, setWithdrawalMobile] = useState<boolean>(false);
  const [gamesMobile, setGamesMobile] = useState<boolean>(false);
  const [jackpotMobile, setJackpotMobile] = useState<boolean>(false);
  const handleDepositsMobile = (): void => (depositsMobile ? setDepositsMobile(false) : setDepositsMobile(true));
  const handleWithdrawalMobile = (): void => (withdrawalMobile ? setWithdrawalMobile(false) : setWithdrawalMobile(true));
  const handleGamesMobile = (): void => (gamesMobile ? setGamesMobile(false) : setGamesMobile(true));
  const handleJackpotMobile = (): void => (jackpotMobile ? setJackpotMobile(false) : setJackpotMobile(true));
  const handleViewJackpots = () => {
    history.push("/view-jackpots");
    mobileNavBar();
  };

  const handleSuccessDepositClick = () => {
    history.push("/successfull-deposits");
    mobileNavBar();
  };
  const handleFailedDepositClick = () => {
    history.push("/failed-deposits");
    mobileNavBar();
  };
  const handlePendingDepositClick = () => {
    history.push("/pending-deposits");
    mobileNavBar();
  };
  const handleSuccessWithdrawClick = () => {
    history.push("/successfull-withdrawal");
    mobileNavBar();
  };
  const handleFailedWithdrawClick = () => {
    history.push("/failed-withdrawal");
    mobileNavBar();
  };
  const handlePendingWithdrawClick = () => {
    history.push("/pending-withdrawal");
    mobileNavBar();
  };
  //   const handleInboxClick = () => history.push("/inbox");
  const handleAddJackpotClick = () => {
    history.push("/add-jackpot");
    mobileNavBar();
  };
  const handleAddGamesClick = () => {
    history.push("/add-games");
    mobileNavBar();
  };
  const handleViewGames = () => {
    history.push("/view-games");
    mobileNavBar();
  };
  const handleViewJackpotSlips = () => {
    history.push("/jackpot-slips");
    mobileNavBar();
  };

  const handleLogoutButton = () => {
    dispatch(deleteUser());
    history.push("/");
  };
  return (
    <motion.div className="absolute w-screen top-12 h-screen z-10 bg-sideBar flex items-start flex-col gap-4 cursor-pointer " initial={{ y: "-100px" }} animate={{ y: 0 }} exit={{ y: "-100px" }}>
      <div className="text-white drop font-poppins font-normal mt-6 mb-1   rounded-sm">
        {nav_permissions.A && (
          <Link to="/deposits" className="group flex flex-row md:gap-6 sm:gap-1 cursor-pointer hover:border-l-4 hover:border-contrast rounded-sm" onClick={() => mobileNavBar()}>
            <div className="text-white font-poppins font-bold ml-2">Transactions</div>
          </Link>
        )}
      </div>
      <div className="text-white drop font-poppins font-normal mt-6 mb-1   rounded-sm">
        {nav_permissions.C && (
          <Link to="/inbox" className="flex flex-row gap-6 ml-1 cursor-pointer hover:border-l-4 hover:border-contrast rounded-sm" onClick={() => mobileNavBar()}>
            <div className="text-white font-poppins font-bold">Inbox</div>
          </Link>
        )}
      </div>
      <div className="text-white drop font-poppins font-normal mt-6 mb-1   rounded-sm">
        <div className="text-white font-poppins font-bold w-screen">
          <div className="flex w-full justify-between" onClick={handleGamesMobile}>
            <div className="mx-2">Games</div>
            <div className="mx-4">
              <ArrowBackIosNewIcon style={{ fill: "white", height: "1rem", width: "1rem", rotate: "-90deg" }} />
            </div>
          </div>
          {gamesMobile && (
            <motion.div className="w-full text-md font-normal mt-2 bg-bgColorOne px-2 py-4 flex flex-col gap-8">
              <div onClick={handleAddGamesClick}>Add Games</div>
              <div onClick={handleViewGames}>View Games</div>
            </motion.div>
          )}
        </div>
      </div>
      <div className="text-white drop font-poppins font-normal mt-6 mb-1   rounded-sm">
        <div className="text-white font-poppins font-bold w-screen">
          <div className="flex w-full justify-between" onClick={handleJackpotMobile}>
            <div className="mx-2">Jackpot</div>
            <div className="mx-4">
              <ArrowBackIosNewIcon style={{ fill: "white", height: "1rem", width: "1rem", rotate: "-90deg" }} />
            </div>
          </div>
          {jackpotMobile && (
            <motion.div className="w-full text-md font-normal mt-1 bg-bgColorOne px-2 py-4 flex flex-col gap-8">
              <div onClick={handleAddJackpotClick}>Add Jackpot</div>
              <div onClick={handleViewJackpots}>View Jackpot</div>
              <div onClick={handleViewJackpotSlips}>Jackpot Slips</div>
            </motion.div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="flex justify-center w-1/3 items-center bg-contrast py-2 px-4 rounded">
          <button onClick={handleLogoutButton}>Logout</button>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileNav;
