import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IPeriod {
  period: string;
}

const PeriodSlice = createSlice({
  name: "period",
  initialState: {
    period: "Today",
  } as IPeriod,
  reducers: {
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    getPeriod: (state: IPeriod) => {
      return state;
    },
  },
});

export const { setPeriod, getPeriod } = PeriodSlice.actions;

export default PeriodSlice.reducer;
