import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

// Define a type for the slice state
interface UserState {
  token: string;
  email: string;
  loggedIn: boolean;
  user_permissions: any;
}

// Define the initial state using that type
const initialState: UserState = {
  token: "",
  email: "",
  loggedIn: false,
  user_permissions: {},
};

export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addUser: (state: UserState, action: PayloadAction<UserState>) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.loggedIn = true;
      state.user_permissions = action.payload.user_permissions;
    },
    deleteUser: (state: UserState) => {
      state = initialState;
      return initialState;
    },
  },
});

export const { addUser, deleteUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
