import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { motion } from "framer-motion";
import Moment from "moment";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "../../App/hooks";
import Loader from "../Universal/Loader";

interface IPROPS {
  datasourceURL: string;
  cacheName: string;
}
const OutboxDataTable: FC<IPROPS> = ({
  datasourceURL,
  cacheName,
}): JSX.Element => {
  const [gridApi, setGridApi] = useState(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const queryClient = useQueryClient();

  const { user_permissions } = useAppSelector((state) => state.user);

  const notify_err = () =>
    toast("Something Went Wrong. Please Try Again Later");

  const getSmsInbox = async (): Promise<any> => {
    let data = await axios({
      method: "GET",
      url: datasourceURL,
      params: {
        limit: 1000,
      },
      headers: {
        "x-access-token": "RQ<l/L6265c-clOg%D}V~MQK0fBHF{",
      },
    });

    return data.data.data;
  };
  const mutations = useMutation(getSmsInbox, {
    onSuccess: (data) => {
      queryClient.setQueryData(cacheName, data);
    },
  });
  const { data, isLoading, isError, error, isFetching } = useQuery(
    cacheName,
    getSmsInbox
  );

  if (isLoading || isFetching) {
    return <Loader />;
  }
  if (isError || error) {
    notify_err();
  }

  const formatInsertedTime = (params: any) => {
    return Moment(params.data["createdAt"]).format("DD/MM/YYYY HH:mm");
  };
  const Time = (params: any) => {
    return Moment(params.data["inserted_at"]).format("HH:mm");
  };

  const onQuickFilterChanged = (value: string) => {
    //@ts-ignore: Object is possibly 'null'
    gridApi.setQuickFilter(value);
  };
  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);
  };

  const onBtnExport = () => {
    //@ts-ignore: Object is possibly 'null'
    gridApi.exportDataAsCsv();
  };

  const handleNextPageClick = (): void => {
    setPageNumber((prevNumber) => (prevNumber = prevNumber + 1));
    setTimeout(() => {
      mutations.mutate();
    }, 500);
  };

  const handlePrevPageClick = (): void => {
    if (pageNumber > 0) {
      setPageNumber((prevNumber) => (prevNumber = prevNumber - 1));
      setTimeout(() => {
        if (mutations.isLoading) {
          return <Loader />;
        }
        mutations.mutate();
      }, 500);
    } else {
      return;
    }
  };

  let filterParams = {
    comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    maxValidYear: 2021,
  };

  return (
    <div className="ag-theme-alpine w-full h-5/6 md:h-[90%]">
      <div className="flex mb-2 w-full sm:justify-start md:justify-end gap-2 items-center">
        <div className="w-1/2">
          <input
            type="text"
            placeholder="search"
            className="border border-bgColorTwo px-4 py-2 rounded  focus:border-contrast focus:outline-none w-full"
            onChange={(e) => onQuickFilterChanged(e.target.value)}
          />
        </div>
        <div
          className="group relative border px-4 py-2 rounded  focus:border-contrast focus:outline-none cursor-pointer overflow-hidden"
          onClick={handleNextPageClick}
        >
          <span className="bg-contrast absolute h-full w-0 top-0 left-0 group-hover:w-full transition-all ease-in duration-300"></span>
          <span className=" relative font-poppins font-semibold group-hover:text-white">
            Next
          </span>
        </div>
        <div
          className="group relative border px-4 py-2 rounded  focus:border-contrast focus:outline-none cursor-pointer"
          onClick={handlePrevPageClick}
        >
          <span className="bg-contrast absolute h-full w-0 top-0 left-0 group-hover:w-full transition-all ease-linear duration-300"></span>
          <span className=" relative font-poppins font-semibold group-hover:text-white">
            Prev
          </span>
        </div>
        {"D-001" in user_permissions && (
          <div
            className="group relative border px-4 py-2 rounded  focus:border-contrast focus:outline-none  cursor-pointer hidden md:flex"
            onClick={onBtnExport}
          >
            <span className="bg-contrast absolute h-full w-0 top-0 left-0 group-hover:w-full transition-all ease-linear duration-300"></span>
            <span className=" relative font-poppins font-semibold group-hover:text-white">
              Download
            </span>
          </div>
        )}
      </div>
      {mutations.isLoading ? (
        <Loader />
      ) : (
        <motion.div
          className="ag-theme-alpine w-full"
          style={{ height: "100%", width: "100%" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.7 }}
        >
          <AgGridReact
            //@ts-ignore
            rowData={data}
            pagination
            paginationPageSize={20}
            onGridReady={onGridReady}
            enableCellTextSelection
            suppressCellSelection
            animateRows
          >
            <AgGridColumn
              field="message"
              minWidth={300}
              flex={1}
              resizable
              cellStyle={{ whiteSpace: "normal" }}
              filter
              wrapText={true}
              autoHeight={true}
            ></AgGridColumn>

            <AgGridColumn field="receiver" minWidth={150}></AgGridColumn>
            <AgGridColumn
              field="inserted_at"
              minWidth={150}
              sortable={true}
              filter="agDateColumnFilter"
              filterParams={filterParams}
              valueGetter={formatInsertedTime}
            ></AgGridColumn>
          </AgGridReact>
        </motion.div>
      )}
    </div>
  );
};

export default OutboxDataTable;
